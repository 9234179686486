import React from 'react'
import { FormattedMessage } from "react-intl";
import {
  allMembers
} from "../../../constants/constants";
import TeamCard from "./TeamCard";
import { graphql, useStaticQuery } from "gatsby";

import './TeamSection.scss';

const TeamSection = () => {
    //TODO: get paths to json files, extract everything from graphql
    const images = useStaticQuery(graphql`
        fragment Image on File {
            childImageSharp {
                    gatsbyImageData(
                        quality: 90
                        width: 200
                        height: 200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                  }
        }
        query {
         stefanMiric: file(
            relativePath: { eq: "team/stefan-miric.jpg" }
         ) {
              ...Image
           }
         nikolaVukovic: file(
            relativePath: { eq: "team/nikola-vukovic.jpg" }
         ) {
              ...Image
           }
        nikolaKlipa: file(
            relativePath: { eq: "team/nikola-klipa.jpg" }
        ) {
              ...Image
          }
        nikolaSreckovic: file(
            relativePath: { eq: "team/nikola-sreckovic.jpg" }
        ) {
              ...Image
          }
        brankoVitorovic: file(
            relativePath: { eq: "team/branko-vitorovic.jpg" }
        ) {
              ...Image
          }
        nikolaJankovic: file(
            relativePath: { eq: "team/nikola-jankovic.jpg" }
        ) {
              ...Image
          }
        julijaDimitrijevic: file(
            relativePath: { eq: "team/julija-dimitrijevic.jpg" }
        ) {
              ...Image
          }
        andrejCvoro: file(
            relativePath: { eq: "team/andrej-cvoro.jpg" }
        ) {
              ...Image
          }
        nenadPalinkasevic: file(
            relativePath: { eq: "team/nenad-palinkasevic.jpg" }
        ) {
             ...Image
          }
        nebojsaMajkic: file(
            relativePath: { eq: "team/nebojsa-majkic.jpg" }
        ) {
              ...Image
          }
        aleksandarBegovic: file(
            relativePath: { eq: "team/aleksandar-begovic.jpg" }
        ) {
              ...Image
          }
        nikolaRoganovic: file(
            relativePath: { eq: "team/nikola-roganovic.jpg" }
        ) {
              ...Image
          }
        ilijaSmiljanic: file(
            relativePath: { eq: "team/ilija-smiljanic.jpg" }
        ) {
              ...Image
          }
        jelenaKoren: file(
            relativePath: { eq: "team/jelena-koren.jpg" }
        ) {
              ...Image
          }
        matijaPojatar: file(
            relativePath: { eq: "team/matija-pojatar.jpg" }
        ) {
              ...Image
          }
        pavlePrica: file(
            relativePath: { eq: "team/pavle-prica.jpg" }
        ) {
              ...Image
          }
        sandraStojanov: file(
            relativePath: { eq: "team/sandra-stojanov.jpg" }
        ) {
              ...Image
          }
        zarkoStamenic: file(
            relativePath: { eq: "team/zarko-stamenic.jpg" }
        ) {
              ...Image
          }
        filipJosipovic: file(
            relativePath: { eq: "team/filip-josipovic.jpg" }
        ) {
              ...Image
          }
        rajkoZagorac: file(
            relativePath: { eq: "team/rajko-zagorac.jpg" }
        ) {
              ...Image
          }
        aleksaUrosevic: file(
            relativePath: { eq: "r&d/team/aleksa-urosevic.jpg" }
        ) {
              ...Image
          }
        aleksandarSpremo: file(
            relativePath: { eq: "r&d/team/aleksandar-spremo.jpg" }
        ) {
              ...Image
          }
        bojanAntunovic: file(
            relativePath: { eq: "r&d/team/bojan-antunovic.jpg" }
        ) {
              ...Image
          }
        ivanDzambasanovic: file(
            relativePath: { eq: "r&d/team/ivan-dzambasanovic.jpg" }
        ) {
              ...Image
          }
        aleksandarMilosavljevic: file(
            relativePath: { eq: "team/aleksandar-milosavljevic.jpg" }
        ) {
              ...Image
          }
        dimitrijeGlibic: file(
            relativePath: { eq: "team/dimitrije-glibic.jpg" }
        ) {
              ...Image
          }
        miljanIlic: file(
            relativePath: { eq: "team/miljan-ilic.jpg" }
        ) {
              ...Image
          }
        martinaMihajlovic: file(
            relativePath: { eq: "team/martina-mihajlovic.jpg" }
        ) {
              ...Image
          }
        leonardMarsanic: file(
            relativePath: { eq: "team/leonard-marsanic.jpg" }
        ) {
              ...Image
          }
        }
    `);

    return (
        <div className="team-section-wrapper padding center content">
            <h1 className="title">
                <FormattedMessage
                    id='meet_team'
                />
            </h1>
            <div className="no-title team-section">
                <div className="team-cards">
                    {allMembers.map((member, i) => (
                        <TeamCard
                            member={member}
                            teamSection="boss"
                            img={images[member.image]}
                            key={`${i}${member.name}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TeamSection;
